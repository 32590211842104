@import "components/buttons";

.rating {
  .stars {
    display: inline-block;
    text-align: left;
    width: 110px;
    height: 17px;
    line-height: 17px;
    background: url(/images/frontend/star.png) repeat-x;
    background-position: 0px -16px;
    vertical-align: middle;
    span {
      display: inline-block;
      line-height: 30px;
      height: 16px;
      background: url(/images/frontend/star.png) repeat-x;
      background-position: 0px 0px;
    }
  }
}
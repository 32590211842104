@use "sass:map";

#products_detailed {
  #products_add2cart {
    .rating {
      a {
        font-size: 12px;
      }
    }
  }
}
.product_detailed_description_wrp {
  .tab {
    margin-bottom: 8px;

    button {
      margin-right: 8px;
      padding: 13px 26px;

      display: inline-block;

      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      color: $color-black;
      background-color: $color-light-green;

      border-radius: 90px;
      border: none;
      white-space: nowrap;

      transition: all .3s;

      &.active, &:hover, &:focus {
        color: $color-white;
        background-color: $color-orange;
      }
    }
  }

  .tabcontent {
    padding: 10px 0;

    table {
      max-width: 100%;
      display: block;
    }

    ul li {
      margin-left: 16px;
    }

    image, img {
      max-width: 100%;
      height: auto;
    }
  }
}

.short_description_div {
  margin-top: 40px;
}

#product_reviews {
  .review {
    padding: 15px 0;
    border-bottom: 1px solid #d3d7db;
    .info {
      margin-bottom: 10px;
    }
    .date {
      font-style: italic;
    }
  }
  .btn-wrp {
    margin: 20px 0;
  }
}


#addReview {
  .name {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .close {
    position: absolute;
    right: 30px;
    top: 12px;
    width: 30px;
    line-height: 30px;
    font-size: 40px;
  }
  .rating {
    position: relative;
    span.hover {
      &.hover1 {
        width: 22px;
      }
      &.hover2 {
        width: 44px;
      }
      &.hover3 {
        width: 66px;
      }
      &.hover4 {
        width: 88px;
      }
      &.hover5 {
        width: 110px;
      }
      &.stars1 {
        width: 22px;
      }
      &.stars2 {
        width: 44px;
      }
      &.stars3 {
        width: 66px;
      }
      &.stars4 {
        width: 88px;
      }
      &.stars5 {
        width: 110px;
      }
    }
    a {
      position: absolute;
      width: 22px;
      height: 100%;
      cursor: pointer;

      &.rate1 {
        left: 0px;
      }
      &.rate2 {
        left: 22px;
      }
      &.rate3 {
        left: 44px;
      }
      &.rate4 {
        left: 66px;
      }
      &.rate5 {
        left: 88px;
      }
    }
  }
}

.b2b_product_element {
  width: 100%;

  .img_td {
    width: 100px;
    img {
      margin: 0 auto;
      transition: transform .2s; /* Animation */
      &:hover{
        transform: scale(2);
      }
    }
  }

  .title_td {
    text-align: left;

    .name {
      text-transform: uppercase;
    }
    .code {
      color: gray;
    }
    .quantity {
      font-weight: bold;

      .dot {
        height: 10px;
        width: 10px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }
      .red_dot {
        background-color: red;
      }
      .green_dot {
        background-color: green;
      }
    }
  }

  .price_td {
    width: 100px;
    .extra_price_info_container {
      position:relative;

      .extra_price_info {
        position:absolute;
        bottom: 26px;
        left: 0;

        padding: 4px;

        width: 100px;

        background: white;
        text-align: center;

        border: 1px solid #666;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        -webkit-box-shadow: 2px 2px 4px #888;
        -moz-box-shadow: 2px 2px 4px #888;
        box-shadow: 2px 2px 4px #888;
        display: none;

      }


      .extra_price_bubble {
        height: 10px;
        width: 10px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }
      .extra_price_bubble:hover+.extra_price_info {
        display: block;
      }
    }
  }

  .amount_td {
    width: 130px;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      display: none;
    }
  }

  .bag_td {
    width: 125px;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      display: none;
    }
  }
}

@use "sass:map";

@mixin col($numCols, $colIdx){
  flex: 0 0 (100% / $numCols) * $colIdx;
  max-width: (100% / $numCols) * $colIdx;
}
@mixin col-offset($numCols, $colIdx){
  margin-left: (100% / $numCols) * $colIdx;
}
@mixin col-push($numCols, $colIdx){
  left: (100% / $numCols) * $colIdx;
}
@mixin col-pull($numCols, $colIdx){
  right: (100% / $numCols) * $colIdx;
}

@mixin cols-xs($numCols){
  @for $colIdx from 1 through $numCols {
    > .col-xs-#{$colIdx} {
      @include col($numCols, $colIdx);
    }
    > .col-xs-offset-#{$colIdx} {
      @include col-offset($numCols, $colIdx);
    }
    >.col-xs-push-#{$colIdx} {
      @include col-push($numCols, $colIdx);
    }
    > .col-xs-pull-#{$colIdx} {
      @include  col-pull($numCols, $colIdx);
    }
  }
}
@mixin cols-sm($numCols){
  @for $colIdx from 1 through $numCols {
     > .col-sm-#{$colIdx} {
      @include col($numCols, $colIdx);
    }
     > .col-sm-offset-#{$colIdx} {
      @include col-offset($numCols, $colIdx);
    }
     > .col-sm-push-#{$colIdx} {
      @include col-push($numCols, $colIdx);
    }
     > .col-sm-pull-#{$colIdx} {
      @include col-pull($numCols, $colIdx);
    }
  }
}
@mixin cols-md($numCols) {
  @for $colIdx from 1 through $numCols {
     > .col-md-#{$colIdx} {
      @include col($numCols, $colIdx);
    }
     >  .col-md-offset-#{$colIdx} {
      @include col-offset($numCols, $colIdx);
    }
     > .col-md-push-#{$colIdx} {
      @include col-push($numCols, $colIdx);
    }
     > .col-md-pull-#{$colIdx} {
      @include col-pull($numCols, $colIdx);
    }
  }
}
@mixin cols-lg($numCols) {
  @for $colIdx from 1 through $numCols {
     > .col-lg-#{$colIdx} {
      @include col($numCols, $colIdx);
    }
     > .col-lg-offset-#{$colIdx} {
      @include col-offset($numCols, $colIdx);
    }
     > .col-lg-push-#{$colIdx} {
      @include col-push($numCols, $colIdx);
    }
     > .col-lg-pull-#{$colIdx} {
      @include col-pull($numCols, $colIdx);
    }
  }
}

@mixin col-set($numCols){
  .col-#{$numCols} {

    @include cols-xs($numCols);
    @include cols-sm($numCols);
    @include cols-md($numCols);
    @include cols-lg($numCols);

    @include cols-xs(1);

    @media (max-width: $screen-sm-min) {
      @include cols-sm(1);
    }

    @media (max-width: $screen-md-min) {
      @include cols-md(1);
    }

    @media (max-width: $screen-lg-min) {
      @include cols-lg(1);
    }
  }
}

.submenu_mega {
  .submenu_groups {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @for $x from 1 through 10 {
    .col-#{$x} {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @include col-set($x);
  }
}


@mixin meniu-undeline() {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  height: 3px;
  width: 100%;
  background: rgba(0,0,0,0.15);
}

@media (max-width: 992px) {
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list, nav #pages_mega_menu #mega_menu .list-dropdown .level-3 {
     position: inherit;
     top: auto;
     bottom: auto;
     left: auto;
     right: auto;
     z-index: auto;
     width: auto;
     transition: left 0.5s;
     display: none;
     padding: 0;
     background: #fff;
     overflow-y: scroll;
  }
}
@media (max-width: 992px) {
  nav #pages_mega_menu #mega_menu .list-collapse-mobile .level-3 ul {
    margin: 0;
    li a {
      padding: 0 10px;
      //margin-left: -30px;
    }
  }
}

#pages_mega_menu {
  > .icon-close {
    position: absolute;
    top: 15px;
    right: 16px;
    font-size: 32px;
    z-index: 3;
  }
  #mega_menu_overlay {}
  #mega_menu {
    list-style: none;
    padding: 0;

    * {
      border: none;
      text-decoration: none;
    }
    .title {
      position: relative;
      display: block;
      font-weight: 500;
      font-size: 16px;
      color: #000;
      @media (min-width: map.get($grid-breakpoints, "lg")) {
        color: #fff;
      }

      a {
        padding: 0;
        font-weight: inherit;
        font-size: inherit;
        color: #000;
        @media (min-width: map.get($grid-breakpoints, "lg")) {
          color: #fff;
        }
      }

      &.title-lvl-1 {
        > a {
          @media (min-width: map.get($grid-breakpoints, "lg")) {
            min-height: 50px;
          }
        }
      }
      &.title-lvl-2 {
        a {
          color: #000;
        }
      }
      &.title-lvl-3 {
        a {
          color: #000;
          display: block;
        }
      }
    }
    .mobile-head, .mobile-head-list {
      flex-shrink: 0;
      position: relative;
      margin: 5px 24px 5px 24px;
      padding: 10px 30px 10px 30px;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      font-family: $font-family-hind-siliguri;
      text-align: center;
      border-bottom: 1px solid #dee2e6;
      color: #000;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 1px;
        height: 13px;
        width: 13px;
        border-left: 2px solid #343a40;
        border-bottom: 2px solid #343a40;
        transform: translateY(-50%) rotate(45deg);
      }
    }
    .submenu_list {
      .menu_list_submenu {
        background: transparent;
      }
      &.open {
        display: block;
      }
    }
    .open {
      .submenu_list {
        display: block !important;
        .row, .mobile-head {
          display: block !important;
        }
      }
    }
    > li {
      > .list-dropdown {
        > .submenu_list {

        }
      }
    }
  }
  .list-collapse ul, .list-collapse-mobile ul {
    background: none;

    .title, a {
      &:hover {
        background: none;
      }
    }
  }

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    flex-grow: 1;
    padding: 0 10px;
    overflow: auto;
    color: #000;

    #mega_menu {
      .title {
        padding: 10px 40px 10px 0;
        border-bottom: 1px solid #dee2e6;

        &:after {
          display: none;
        }
        &.has_submenu {


          &:after {
            //content: '\e816';
            position: absolute;
            top: 14px;
            right: 0;
            display: inline-block;
            margin: 0;
            width: 15px;
            font-size: 20px;
            line-height: 18px;
            font-family: "fontello", serif;
            text-decoration: inherit;
            text-align: center;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }
      }
      .submenu_list {
        position: fixed !important;
        top: 0;
        left: 0;
        bottom: 0px !important;
        z-index: 1 !important;
        //height: 100%;
        width: 100% !important;
        background: #ffffff;
      }
    }
    .submenu_list {
      > .container-fluid {
        padding: 0;
      }
      .menu_list_submenu {
        li:not(.mobile-head):not(.mobile-head-list) {
          padding-left: 24px;
          padding-right: 24px;
        }
      }
    }
    .submenu_groups {
      display: flex;
      flex-direction: column;
      height: 100%;

      .panels-wrp {
        flex-grow: 1;
        overflow: auto;
        padding: 0 24px;

        [class^="panel-col-"] {
          > .menu_column {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    height: 100%;

    > .icon-close {
      display: none;
    }
    #mega_menu {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      justify-content: center;
      column-gap: 30px;
      margin: 0;
      height: 100%;

      .title {
        padding: 0;
        font-weight: 400;
        font-size: 14px;

        &.has_submenu:after, &:after {
          display: none;
        }
      }
      > li {
        //position: relative;
        height: 100%;

        > .list-dropdown > .title, > .title {
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;

          a {
            display: flex;
            align-items: center;
            height: 100%;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;

            &:hover {
              //font-weight: 700;
              opacity: 1;
            }
          }
        }

        > .list-dropdown {
          display: flex;
          align-items: center;
          height: 100%;

          &.hover {
            > .title {
              //font-weight: 700;

              &:after {
                @include meniu-undeline();
              }
            }
          }
        }
        > .title {
          &:hover {
            font-weight: 700;

            &:after {
              @include meniu-undeline();
            }
          }
        }
        &.mega_element {
          .list-dropdown {
            position: static;

            .submenu_mega {
              top: 100%;
              padding: 40px 0;
              width: 100%;
              border: none;
              box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
              z-index: 1;

              //top: 100%;
              background: #ffffff;

              .submenu_groups {
                display: flex;
                flex-direction: column;
                height: 100%;

                .panels-wrp {
                  display: grid;
                  grid-auto-flow: column;
                  column-gap: 20px;

                  .panel-col-width-1 { grid-column: span 1; }
                  .panel-col-width-2 { grid-column: span 2; }
                  .panel-col-width-3 { grid-column: span 3; }
                  .panel-col-width-4 { grid-column: span 4; }
                  .panel-col-width-5 { grid-column: span 5; }
                  &.panel-columns-1 { grid-template-columns: repeat(1, minmax(0, 1fr)); }
                  &.panel-columns-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
                  &.panel-columns-3 { grid-template-columns: repeat(3, minmax(0, 1fr)); }
                  &.panel-columns-4 { grid-template-columns: repeat(4, minmax(0, 1fr)); }
                  &.panel-columns-5 { grid-template-columns: repeat(5, minmax(0, 1fr)); }
                  &.panel-columns-6 { grid-template-columns: repeat(6, minmax(0, 1fr)); }

                  &.multiple {
                    &.panel-columns-3, &.panel-columns-4, &.panel-columns-5, &.panel-columns-6 {
                      [class^="panel-col-"]:last-child {
                        //&.panel-col-width-1 { grid-column: span 1 / 7; }
                        //&.panel-col-width-2 { grid-column: span 2 / 7; }
                        //&.panel-col-width-3 { grid-column: span 3 / 7; }
                        //&.panel-col-width-4 { grid-column: span 4 / 7; }
                        //&.panel-col-width-5 { grid-column: span 5 / 7; }
                        //&.panel-col-width-6 { grid-column: span 6 / 7; }
                      }
                    }
                  }
                  [class^="panel-col-"] {
                    > .menu_column {
                      margin-bottom: 20px;

                      .menu_item {
                        .title {
                          a {
                            display: block;
                            padding: 10px 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &:not(.mega_element) {
          > .list-dropdown {
            > .submenu_list {
              top: 100%;
              min-width: 100%;

              > .menu_list_submenu {
                width: 100%;
                background: #ffffff;

                > li {
                  .title {
                    a {
                      display: block;
                      padding: 10px;
                      min-width: 160px;
                    }
                  }
                  &.has_child {
                    position: relative;

                    > .title {
                      position: relative;
                      display: block;
                      padding: 10px 30px 10px 10px;

                      > a {
                        display: block;
                        padding: 0;
                      }
                      &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-right: 1px solid #343a40;
                        border-bottom: 1px solid #343a40;
                        transform: translateY(-50%) rotate(-45deg);
                      }
                    }
                    .submenu_list {
                      display: none;
                      position: absolute;
                      top: 0;
                      left: 100%;
                      padding: 0;
                      background: #ffffff;
                      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

                      li {
                        a {
                          display: block;
                          padding: 10px;
                        }
                      }
                    }
                    &.hover {
                      > .submenu_list {
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }
}


.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (max-width: 992px) {
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list ul li h4.level-2 {
    border-bottom: 1px solid #dee2e6;
  }
}
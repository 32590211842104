@use "sass:map";

#footer {

  .footer-logo {
    max-width: 188px;
  }

  .footer-bottom {
    background-color: $color-light-green;
  }

  .payments_title {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 500;
    color: #000;

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      text-align: right;
    }
  }

  .footer_payments {
    display: block;

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      display: flex;
      flex-direction: row;
    }


    .left {
      flex: 1;
      text-align: center;

      .payment {

        @media (min-width: map.get($grid-breakpoints, "lg")) {
          margin-right: 13px;
        }
      }
    }

    .right {
      flex: 1;
      text-align: center;

      .payment {

        @media (min-width: map.get($grid-breakpoints, "lg")) {
          margin-left: 4px;
        }
      }
    }

    .payment {
      margin-bottom: 16px;
      text-align: center;


      img {
        max-width: 100%;
      }
    }

  }

  #subscribers_subscribe {
    #newsletter_form .btn {
      padding: 8px 9px;
      color: #000;
      border: 1px solid #DA8B21;
      background: #DA8B21;
      font-size: 15px;

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        padding: 7px 9px;
        font-size: 17px;
      }
    }
  }
}

#footer .footer-bottom .footer-menu-wrapper {
  padding: 45px 0 0;
}
@use "sass:map";

body {
  @include text();
}

h1, .h1 {
  @include heading1();
}
h2, .h2 {
  @include heading2();
}
h3, .h3 {
  @include heading3();
}

h4, .h4 {
  @include heading4();
}

h5, .h5 {
  @include heading5();
}
p , span, li {
  @include text();
}

th, label {
  @include small-text();
}


#lazy_loader {
  color: $color-light-blue;
}

.linepromos {
  .item {
    height: auto;
  }
  @media (max-width: map.get($grid-breakpoints, "lg")) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

header {
  //@media (max-width: map.get($grid-breakpoints, "lg")) {
  //  top: 0;
  //}

  #logo {
    max-width: 188px;

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding: 15px 0;
    }
  }
}

#banners_small {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    #banners-small-slider div.banner:hover .btn {
      border: 1px solid $color-blue2 !important;
      background: $color-blue2 !important;
      color: $color-white !important;
    }
  }
}

#products_detailed {
  .tags .tag {
    &.transparent {
      background: transparent;
    }
  }
}

.product_listing .product_element > a {
  .tags .tag {
    &.transparent {
      background: transparent;
    }
  }
}
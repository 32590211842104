.html_content {
  ul {
    li {
      margin-left: 20px;
    }
  }
}

.cookie_bar {
  height: auto;
  .close {
    margin-top: 0;
  }
}
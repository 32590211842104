@mixin btn-primary() {

  border: 1px solid $color-orange;
  background:$color-orange;
  color: $color-white !important;
  opacity: 1;

  &:hover, &.active{
    border: 1px solid $color-orange;
    background:$color-orange;;
    color: $color-white !important;
    opacity: 0.8;
  }

  &.disabled{
    background: rgba(141, 130, 120, 0.24);
    opacity: 0.8;
  }
}
@mixin btn-custom() {

  border: 1px solid $color-black;
  background: $color-white;
  color: $color-black !important;
  opacity: 1;

  &:hover, &.active{
    border: 1px solid $color-blue2 !important;
    background:$color-blue2 !important;
    color: $color-black !important;
    //opacity: 0.8;
  }

  &.disabled{
    background: rgba(141, 130, 120, 0.24);
    //opacity: 0.8;
  }
}

//
input, button, div, span, a {
  .btn-primary {
    @include btn-primary;
  }
  .btn-custom, .btn-secondary {
    @include btn-custom;
  }
}

.btn-google {
  padding: 5px 23px 5px 43px;
  font-size: 18px;
  line-height: 54px;
  background-color: transparent;
  position: relative;

  .icon {
    position: absolute;
    left: 15px;
    margin-top: 18px;
    width: 16px;
    height: 16px;


    &:before {
      display: none;
    }
  }
}

.btn-google {
  color: #DC6157;
  border: 1px solid #DC6157;

  &:hover, &:active, &:focus {
    color: #DC6157;
    background-color: white;
    border-color: #DC6157;
    opacity: 0.8;
  }

  .icon {
    background-image: url("/images/frontend/login-gmail.png");
  }
}
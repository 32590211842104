@use "sass:map";

nav #pages_mega_menu {
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    background-color: $color-blue2;
  }
  #mega_menu li > a > .title {
    @media (max-width: map.get($grid-breakpoints, "lg")) {
      padding: 10px 0 10px 0;

      .icon {
        margin-right: -10px;
      }
    }
  }
}
.owl-nav .owl-prev {
  left: 15px;
  z-index: 1;
}

#banners_hero {
  .owl-out-nav, .owl-nav {
    .owl-prev {
      left: 15px;
      z-index: 1;
    }
    .owl-next {
      right: 15px;
      z-index: 1;
    }
    &.disabled {
      display: none;
    }
  }
}


.banner_landing {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;

  a, img {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap');

$font-family-hind-siliguri: 'Hind Siliguri', sans-serif;


@mixin heading1() {
  font-family: $font-family-hind-siliguri;
}
@mixin heading2() {
  font-family: $font-family-hind-siliguri;
}

@mixin heading3() {
  font-family: $font-family-hind-siliguri;
}

@mixin heading4() {
  font-family: $font-family-hind-siliguri;
}

@mixin heading5() {
  font-family: $font-family-hind-siliguri;
}

@mixin subtitle() {
  font-family: $font-family-hind-siliguri;
}

@mixin button_text() {
  font-family: $font-family-hind-siliguri;
}

@mixin text() {
  font-family: $font-family-hind-siliguri;
}

@mixin small-text() {
  font-family: $font-family-hind-siliguri;
}
$theme-colors: (
        "primary": #DA8B21,
        "danger": #fa0f3d,
        "success": #5cb85c,
        "info": #5bc0de,
        "warning": #f0ad4e
);

$border-radius: 0 !default;

$font-size-base: 0.875rem;
$line-height-base: 1.42857143;
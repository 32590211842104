
.news_list {
  .news_element {
    overflow: hidden;
    span.date {
      position: relative;
      float: none;
      height: 0;
    }
  }
}

#news_detailed {
  .text_style {
    ul li {
      margin-left: 16px;
    }
  }

  .main_photo {
    background: transparent;
  }

}